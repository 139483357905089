<template>
    <div class="check-out-layout">
        <div class="progress-bar__wrapper">
            <StepProgress :steps="steps" :current-step="currentStep" class="progress-bar" />
        </div>
        <div class="wrapper">
            <router-view />
        </div>
        <PriceSection :cart="cart" class="order-section" />
    </div>
</template>

<script>
    import StepProgress from '@/components/Common/StepProgress/StepProgress.vue';
    import PriceSection from '@/components/Cart/PriceSection.vue';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'CheckOutLayout',
        components: { StepProgress, PriceSection },
        data() {
            return {
                steps: [this.$t('common.buyerInfo'),this.$t('common.delivery'), this.$t('common.payment')],
                currentStep: 0,
            };
        },
        watch: {
            $route: {
                async handler(route) {
                    if (route.name === 'checkOutBuyerInfo') {
                        this.currentStep = 0;
                    }
                    
                    if (route.name === 'checkOutDelivery') {
                        this.currentStep = 1;
                    }

                    if (route.name === 'checkOutPayment') {
                        this.currentStep = 2;
                    }
                },
                immediate: true,
            },
            'cart.length'(val) {
                if (!val) {
                    this.clearCartCookies();
                    this.clearCheckOutsCookies();
                    this.clearDiscountPoints();
                    this.setContinueCheckout(false);
                }
            },
        },
        computed: {
            ...mapGetters('cart', ['cart']),
            ...mapGetters('checkout', ['isContinueCheckout']),
        },
        methods: {
            ...mapActions('checkOut', ['clearCheckOutsCookies', 'setContinueCheckout']),
            ...mapActions('orderInformation', ['setBuyerInformation', 'setDeliveryInformation']),
            ...mapActions('cart', ['clearCartCookies']),
            ...mapActions('discountPoint', ['clearDiscountPoints']),
        },
        beforeRouteLeave() {
            if(this.isContinueCheckout === false) {
                this.clearCheckOutsCookies();
            }

            this.clearDiscountPoints();
        },
        mounted() {
            this.setDeliveryInformation();
            this.setBuyerInformation();
        },
    };
</script>

<style lang="scss" scoped>
    .check-out-layout {
        padding-bottom: 70px;

        .progress-bar__wrapper {
            width: 100%;
        }

        .progress-bar {
            width: 70%;
            margin-bottom: 16px;
        }

        .wrapper {
            margin-bottom: 20px;
        }
    }

    @include media($md) {
        .check-out-layout {
            @include row-align-start-justify-between;
            align-content: flex-start;
            flex-wrap: wrap;

            padding-bottom: 25px;

            .wrapper {
                margin-bottom: 0;
            }

            .wrapper,
            .order-section {
                width: 49%;
            }
        }
    }

    @include media($lg) {
        .check-out-layout {
            .progress-bar {
                margin-bottom: 60px;
            }
        }
    }

    @include media($xl) {
        .check-out-layout {
            .wrapper,
            .order-section {
                width: 48%;
            }
        }
    }

    @include media($xxl) {
        .check-out-layout {
            .wrapper,
            .order-section {
                width: 45%;
            }
        }
    }
</style>
