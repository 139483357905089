<template>
    <div class="title-price">
        <h5>{{ title }}</h5>
        <h5 class="price">
            <span v-if="isDiscount">-</span>
            <span v-if="price != 0">€{{ price }}</span>
            <span v-else>{{ $t('common.free') }}</span>
        </h5>
        <p v-if="description" class="description">{{ description }}</p>
    </div>
</template>

<script>
    export default {
        name: 'TitlePrice',
        props: {
            title: {
                type: String,
                default: '',
            },
            description: {
                type: String,
                default: '',
            },
            price: {
                type: String,
                default: '',
            },
            isDiscount: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .title-price {
        @include row-align-start-justify-between;
        flex-wrap: wrap;

        margin-bottom: 16px;

        .description {
            width: 90%;

            font-size: $font-14;
        }
    }
</style>
