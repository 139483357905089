<template>
    <div class="item__wrapper">
        <TitlePrice
            :title="amount > 1 ? titleWithAmount : $filters.getTranslationByKey(mealKit.name)"
            :price="totalPrice"
        />
        <div class="discount__wrapper" v-if="appliedDiscountCodes.length">
            <CRTag v-for="discount in appliedDiscountCodes" :key="discount.id" class="discount-tag" type="danger">
                {{ discount.text }}
            </CRTag>
        </div>
        <div v-if="!mealKit.isUnique" class="body">
            <CRTag class="tag">{{ $t(`common.${menuType}`) }}</CRTag>
            <CRTag class="tag">{{ calorie.amount }}{{ $t('common.kcal') }}</CRTag>
            <CRTag class="tag">{{ $filters.getTranslationByKey(period.name) }}</CRTag>
            <CRTag class="tag">{{ combination }}</CRTag>
            <CRTag class="tag" v-for="allergen in allergens" :key="allergen.id">
                {{ renderAllergenName(false, allergen, additionalAllergen) }}
            </CRTag>
            <CRTag class="tag" v-for="addition in additions" :key="addition.id">
                {{ $filters.getTranslationByKey(addition.name) }}
            </CRTag>
        </div>
    </div>
</template>

<script>
    import CRTag from '@/components/Common/Tags/CRTag.vue';
    import TitlePrice from '@/components/Cart/TitlePrice.vue';
    import { renderAllergenName } from '@/helpers/AllergenHelper';

    export default {
        name: 'PriceSectionItem',
        components: { CRTag, TitlePrice },
        props: {
            mealKit: {
                type: Object,
                default: () => {},
            },
            combination: {
                type: String,
                default: '',
            },
            calorie: {
                type: Object,
                default: () => {},
            },
            period: {
                type: Object,
                default: () => {},
            },
            allergens: {
                type: Array,
                default: () => [],
            },
            additions: {
                type: Array,
                default: () => [],
            },
            menuType: {
                type: String,
                default: '',
            },
            additionalAllergen: {
                type: String,
                default: '',
            },
            amount: {
                type: Number,
                default: 1,
            },
            totalPrice: {
                type: String,
                default: '',
            },
            appliedDiscountCodes: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
                renderAllergenName,
            };
        },
        computed: {
            titleWithAmount() {
                return `${this.amount} x ${this.$filters.getTranslationByKey(this.mealKit.name)}`;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .item__wrapper {
        border-bottom: 1px solid $grey-line;

        .discount__wrapper {
            @include column-align-start;

            .discount-tag {
                margin-bottom: 8px;
            }
        }

        .body {
            @include row-align-center;

            flex-wrap: wrap;

            .tag {
                margin-right: 10px;
                margin-bottom: 8px;
            }
        }
    }
</style>
