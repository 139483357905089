<template>
    <div class="order-section__wrapper">
        <h4>{{ $t('common.yourOrder') }}</h4>
        <PriceSectionItem
            class="order-section-item"
            v-for="item in cart"
            :key="item.mealKit.id"
            :mealKit="item.mealKit"
            :menu-type="item.menuType?.type"
            :calorie="item.calorie"
            :period="item.period"
            :allergens="item.allergens"
            :additions="item.additions"
            :additional-allergen="item.additionalAllergen"
            :amount="item.amount"
            :total-price="item.totalPrice"
            :applied-discount-codes="item.appliedDiscountCodes"
            :combination="$filters.getTranslationByKey(item.combination?.name)"
        />

        <div v-if="this.buyerInformation" class="information-section">
            <h5 class="">{{ $t('common.client_information') }}</h5>
            
            <div><b>{{ $t('common.first_name') }} {{ $t('common.last_name') }}:</b> {{ this.buyerInformation.userData.firstName }} {{ this.buyerInformation.userData.lastName }}</div>
            <div v-if="this.buyerInformation.userData.companyName"><b>{{ $t('common.company_name') }}:</b> {{ this.buyerInformation.userData.companyName }}</div>
            <div><b>{{ $t('common.email') }}:</b> {{ this.buyerInformation.userData.email }}</div>
            <div><b>{{ $t('common.phone') }}:</b> {{ this.buyerInformation.userData.phone }}</div>
            <div><b>{{ $t('common.address') }}:</b> 
                {{ this.buyerInformation.addressData.street }}, 
                {{ this.buyerInformation.addressData.houseNumber }} - {{ this.buyerInformation.addressData.apartmentNumber }}, 
                {{ this.buyerInformation.addressData.city?.name}}, 
                {{ this.buyerInformation.addressData.postalCode }}
            </div>
        </div>

        <div v-if="this.deliveryInformation" class="information-section">
            <h5 class="">{{ $t('common.delivery_information') }}</h5>
            <div><b>{{ $t('common.deliveryDate')  }}:</b> {{ this.deliveryInformation.deliveryData.regular.deliveryStartDate }} {{ this.deliveryInformation.deliveryData.regular.startTime }} - {{ this.deliveryInformation.deliveryData.regular.endTime }}</div>
            <div><b>{{ $t('common.deliveryMethod') }}:</b> 
                <span v-if="this.deliveryInformation.deliveryMethodId == 1">{{ $t(`deliveryMethods.to_hands`) }}</span>
                <span v-if="this.deliveryInformation.deliveryMethodId == 2">{{ $t(`deliveryMethods.leave_at_door`) }}</span>
                <span v-if="this.deliveryInformation.deliveryMethodId == 3">{{ $t(`deliveryMethods.take_outside`) }}</span>
            </div>
        </div>

        <div class="price-section">
            <TitlePrice
                class="title-price"
                :title="$t('orderManagement.price')"
                :price="pricesObj.priceWithoutDiscount"
            />
            <TitlePrice
                v-if="+pricesObj.commonDiscount"
                class="title-price"
                :title="$t('common.discount')"
                :price="pricesObj.commonDiscount"
                :description="$t('common.discountCodesAndPoints')"
                is-discount
            />
            <TitlePrice
                v-if="isRegularKitInCart"
                :title="$t('common.delivery')"
                :price="pricesObj.regularDeliveryPrice"
                :description="$t('cart.deliveryForRegularKits')"
            />
            <TitlePrice
                v-if="isUniqueKitInCart"
                class="mb-0"
                :title="$t('common.delivery')"
                :price="pricesObj.uniqueDeliveryPrice"
                :description="$t('common.forUniqueKits')"
            />
        </div>
        <div class="total-section">
            <TitlePrice
                class="total-price"
                :title="$t('common.total')"
                :price="pricesObj.totalPrice"
                :description="$t('common.vatAmount', { vat: pricesObj.vat })"
            />
        </div>
    </div>
</template>

<script>
    import PriceSectionItem from '@/components/Cart/PriceSectionItem.vue';
    import TitlePrice from '@/components/Cart/TitlePrice.vue';
    import { mapGetters, mapMutations } from 'vuex';
    import { calcCommonPrices } from '@/helpers/CartHelper';
    import { DeliveryPriceApi, VATApi } from '@/api';

    export default {
        name: 'PriceSection',
        components: { PriceSectionItem, TitlePrice },
        props: {
            cart: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
                vatData: [],
                pricesData: [],
            };
        },
        computed: {
            ...mapGetters('cart', ['isDiscountCodesPresent', 'isUniqueKitInCart', 'isRegularKitInCart']),
            ...mapGetters('discountPoint', ['appliedDiscountPoints']),
            ...mapGetters('orderInformation', ['buyerInformation', 'deliveryInformation']),

            pricesObj() {
                this.setPrices(calcCommonPrices(this.cart, this.pricesData, this.vatData, this.appliedDiscountPoints));

                return calcCommonPrices(this.cart, this.pricesData, this.vatData, this.appliedDiscountPoints);
            },
        },
        methods: {
            ...mapMutations('orderPrices', ['setPrices']),

            async getDeliveryPrices() {
                try {
                    const response = await DeliveryPriceApi.getAll();
                    this.pricesData = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async getVAT() {
                try {
                    const response = await VATApi.getAll();
                    this.vatData = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },
        async mounted() {
            await this.getDeliveryPrices();
            await this.getVAT();
        },
    };
</script>

<style lang="scss" scoped>
    .order-section__wrapper {
        width: 100%;
        padding: 15px;

        background: $primary-500;

        border-radius: $br-20;

        h4 {
            margin-bottom: 16px;
        }

        .order-section-item {
            padding-top: 24px;
            padding-bottom: 16px;

            &:first-of-type {
                padding-top: 0;
            }
        }

        .price-section {
            border-bottom: 1px solid $grey-line;

            padding: 25px 0;

            .title-price:last-child {
                margin-bottom: 0;
            }
        }

        .information-section {
            padding-top: 25px;

            h5 {
                margin-bottom: 10px;
            }
        }

        .total-section {
            padding-top: 25px;

            .total-price {
                margin-bottom: 0;

                &:deep(p) {
                    width: 100%;
                    text-align: end;
                }

                &:deep(.price span) {
                    font-size: $font-30;
                }
            }
        }
    }
</style>
